<script>
import baseMixin from '@/mixins/baseMixin'
import { getStatusLabelHashMap } from '@/helpers/status-helper'

export default {
  name: 'BaseStatusLabel',
  mixins: [baseMixin],
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'grey',
    },
    background: {
      type: String,
      default: '',
    },
    label: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      map: new Map(),
    }
  },
  computed: {
    content() {
      return this.getBadgeFromStr(this.text)
    },
  },
  created: function () {
    this.map = getStatusLabelHashMap()
  },
  methods: {
    getBadgeFromStr(str) {
      const badge = this.map.get(str)
      if (badge) return badge

      return { title: str, color: this.color, icon: this.iconType }
    },
  },
}
</script>

<template>
  <span
    v-if="content"
    :class="['status-badge body-2', capitalize ? 'text-capitalize' : '']"
  >
    <v-tooltip :disabled="!tooltip" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          :outlined="outlined"
          :label="label"
          :color="background ? background : ''"
          :class="['status-badge', background ? '' : 'transparent']"
          v-on="on"
        >
          <v-icon left :color="content.color" small>
            {{ content.icon }}
          </v-icon>
          {{ mobile ? '' : content.title }}</v-chip
        >
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </span>
</template>
